@import './scss/font';
@import './scss/theme';

@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

body {
  font-family: var(--font-family);
}
